import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

import "./landing-page.scss";
import { footer_links } from "../../components/Footer/index";

export default function LandingPage() {
  const [theme, setTheme] = useState("dark");
  const [screenOrientation, setScreenOrientation] = useState("portrait"); // portrait , landscape or square
  const [aspectRatio, setAspectRatio] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = useCallback(() => {
    const orientation =
      window.innerWidth > window.innerHeight ? "landscape" : "portrait";
    const aspectR = window.innerWidth / window.innerHeight;

    setScreenOrientation(orientation);
    setAspectRatio(aspectR);

    if (aspectR < 1.34) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    document.body.className = "dark--very";

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.className = "dark";
    };
  }, []);

  const mainClasses = classNames("landing-page__container", {
    "landing-page--dark": theme === "dark",
  });

  return (
    <div className={mainClasses}>
      <div className="landing-page__content">
        <div className="landing-page__content__tagline-container">
          <div className="landing-page__content__mobile-grid"></div>
          <div className="landing-page__content__tagline">
            an economy rethought
            <span className="landing-page__content__tagline-dot">.</span>
          </div>
          <div className="landing-page__content__productlinks">
            <a
              href="https://auth.loudc.io/n?service=loudcurtain&comingfrom=login"
              target="_blank"
              rel="noopener noreferrer"
            >
              let's login &rarr;
            </a>
          </div>
        </div>
        <div className="landing-page__content__logo-container">
          <svg className="landing-page__content__logo">
            <use
              xlinkHref={
                isMobile ? "#logo--loudc-portrait" : "#logo--loudc-landscape"
              }
            ></use>
          </svg>
        </div>
      </div>
      <div className="landing-page__footer">
        <div className="landing-page__footer__content">
          <div className="landing-page__footer__link-container">
            {/* <a
              href="https://piggypoin.app"
              className="landing-page__footer__link"
            >
              piggypoin
            </a>
            <a href="https://letme.care" className="landing-page__footer__link">
              letme.care
            </a>
            <a href="https://when.black" className="landing-page__footer__link">
              when.black
            </a> */}
            {footer_links.map((link) => (
              <a href={link.url} className="landing-page__footer__link" key={link.id}>
                {link.name}
              </a>
            ))}
          </div>
          <div className="landing-page__footer__copyright">
            LoudCurtain &copy; 2021
          </div>
          <div className="landing-page__footer__secrettagline">
            ⠺⠑ ⠅⠝⠕⠺ ⠊⠞ ⠊⠎ ⠍⠑⠁⠝⠞ ⠞⠕ ⠃⠑ ⠋⠑⠇⠞⠲
          </div>
        </div>
        <div className="landing-page__footer__siloart" />
      </div>
    </div>
  );
}

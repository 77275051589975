// Header Component
import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";

import "./header.scss";

interface HeaderProps {
  embedLocation?: string;
}

export default function Header(props: HeaderProps) {
  const { embedLocation } = props;
  const [isMobile, setIsMobile] = useState(false);
  const [theme, setTheme] = useState("dark");

  const handleResize = useCallback(() => {
    const aspectR = window.innerWidth / window.innerHeight;

    if (aspectR < 1.34) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loudcLogoClasses = classNames("logo--loudc", {
    dark: !theme || theme === "dark",
    "portrait": isMobile
  });

  const takeToLandingPage = () => {
    window.location.href = "/";
  };

  return (
    <header className="header-generalised__container">
      <div onClick={takeToLandingPage} className="logo--loudc-container">
        <svg className={loudcLogoClasses}>
          <use
            xlinkHref={
              isMobile ? "#logo--loudc-portrait" : "#logo--loudc-landscape"
            }
          ></use>
        </svg>
      </div>
    </header>
  );
}

// Contact Page
// Language: typescript

import React from "react";

import "./contact-page.scss";
import Footer from "../../components/Footer/index";
import Header from "../../components/Header/Header";
import { useEffect } from "react";

// Props interface for ContactPage
interface ContactPageProps {
  isMobile: boolean;
}

export default function ContactPage(props: ContactPageProps) {
  const { isMobile } = props;
  const [phoneRevelead, setPhoneRevelead] = React.useState(false);
  const [touchType, setTouchType] = React.useState("click");

  const revealPhoneNumber = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setPhoneRevelead(true);

    if (phoneRevelead) {
      return;
    }

    e.preventDefault();
  };

  useEffect(() => {
    if (isMobile) {
      setTouchType("tap");
    }
  }, [isMobile]);

  //captitalised TouchType
  const capitaliseTouchType = (touchType: string) => {
    return touchType.charAt(0).toUpperCase() + touchType.slice(1);
  };

  return (
    <div className="contact-page__container">
      <Header />
      <main>
        <h1>
          let&apos;s say <span className="braille">⠓⠊</span>
        </h1>
        <p>
          shoot an email at
          <a
            className="mail highlighted-block dark inline-content"
            href="mailto:sayhi@loudcurtain.com"
          >
            <span className="mail__icon icon--left" />
            <span>sayhi@loudcurtain.com</span>
          </a>
        </p>

        <h2>or use our mailing address</h2>
        <p>
          <span className="block">Loudcurtain</span>
          <span className="block">The Gateway West, Level 35</span>
          <span className="block">150 Beach Road</span>
          <span className="block">Singapore 189720</span>
          {/* <a
            className="phone highlighted-block dark inline-content"
            href="tel:+911246688184"
            onClick={revealPhoneNumber}
          >
            <span className="phone__icon icon--left" />
            {phoneRevelead ? (
              <span className="phone__number">+65 6727 8950</span>
            ) : (
              <span className="phone__curtain-raiser">{
                capitaliseTouchType(touchType) + " to reveal phone number"
              }</span>
            )}
          </a> */}
        </p>
        <p>
          <span className="block">Loudcurtain</span>
          <span className="block">Level 18, One Horizon Center</span>
          <span className="block">Golf Course Road, Gurugram</span>
          <span className="block">HR 122002</span>
          <a
            href="https://goo.gl/maps/ZyRKc9Sj8KMUi5XY6"
            target="_blank"
            className="maplink link dark"
          >
            find us on google map &rarr;
          </a>
        </p>

        <h2>if you are a member of press</h2>
        <p>
          use this email address
          <a
            className="mail highlighted-block dark inline-content"
            href="mailto:sayhi@loudcurtain.com"
          >
            <span className="mail__icon icon--left" />
            press@loudcurtain.com
          </a>
        </p>

        <a
          href="https://auth.loudc.io/n?service=loudcurtain&ccomingfrom=whitepaperslink"
          className="contact-page__call-to-serve"
        >
          If you wanna learn more of our hows and whys, get our whitepapers
          &rarr;
        </a>
      </main>
      <Footer embedLocation="contact" />
    </div>
  );
}

import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";

import Footer from "../../components/Footer";
import "./about-page.scss";
import Header from "../../components/Header/Header";

export default function AboutPage() {
  

  return (
    <div className="about-page__container">
      <Header />
      <main>
        <h1>⠅⠊⠁ ⠕⠗⠁</h1>
        <p>
          <span>
            We are on a mission to be in the race of fast-pace research
            happening in the field of science, engineering, social economics and
            health care and working towards generating more jobs that gonna
            involve current and future generations and will require efforts from
            intense research work to manual easy to learn processes that can be
            performed by any member of society around the globe.
          </span>
          <span>
            We do this by helping people and groups who are contributing to the
            society in their best possible way. Which includes people involved
            in handmade product manufacturing, neighborhood teachers, health
            care professionals and small businesses; the original heros of micro
            economies.
          </span>
          <span>
            We are working towards building solutions, products and platforms
            that aims to help these micro economy heros in their day to day
            challenges via new & innovative ways and are sustainable in all
            terms.
          </span>
        </p>

        <a href="https://auth.loudc.io/n?service=loudcurtain&ccomingfrom=whitepaperslink" className="about-page__call-to-serve">If you wanna learn more of our hows and whys, get our whitepapers &rarr;</a>
      </main>
      <Footer embedLocation="about" />
    </div>
  );
}

import React, { useEffect, useState, useCallback } from 'react';
import { Router, Route, Switch, Redirect } from "react-router";
import { createBrowserHistory } from 'history';

import "./styles.scss";
import SVGs from './svg';
import LandingPage from './pages/LandingPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';

const history = createBrowserHistory();

export default function App() {
  // Screen Size Detection being done here and passed as props to all the pages
  const [screenOrientation, setScreenOrientation] = useState("portrait"); // portrait , landscape or square
  const [aspectRatio, setAspectRatio] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = useCallback(() => {
    const orientation =
      window.innerWidth > window.innerHeight ? "landscape" : "portrait";
    const aspectR = window.innerWidth / window.innerHeight;

    setScreenOrientation(orientation);
    setAspectRatio(aspectR);

    if (aspectR < 1.34) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  return (
    <Router history={history}>
      <SVGs />
      <Switch>
        <Route exact path="/">
          <LandingPage  />
        </Route>
        <Route path="/about">
          <AboutPage />
        </Route>
        <Route path="/contact">
          <ContactPage isMobile={isMobile}  />
        </Route>
      </Switch>
    </Router>
  );
}

import React, { useState } from "react";
import classNames from 'classnames';

import "./footer.scss";


interface FooterProps {
  embedLocation?: string;
}

export const footer_links = [
    {
      id: "developers",
      name: "developers",
      url: "https://auth.loudc.io/n?service=loudcurtain&comingfrom=developerlink",
    },
    {
      id: "contact",
      name: "contact",
      url: "/contact",
    },

    {
      id: "about",
      name: "about",
      url: "/about",
    },
  ];

export default function Footer(props: FooterProps) {
  const { embedLocation } = props;
  const [theme, setTheme] = useState("dark");

  

  const footerClasses = classNames({
    [theme]: true
  });

  return (
    <footer className={footerClasses}>
      <div className="footer__content">
        <div className="footer__link-container">
          {/* <a href="https://piggypoin.app" className="footer__link">
            piggypoin
          </a>
          <a href="https://letme.care" className="footer__link">
            letme.care
          </a>
          <a href="https://when.black" className="footer__link">
            when.black
          </a> */}
          {footer_links
            .filter((link) => link.id !== embedLocation)
            .map((link) => (
              <a href={link.url} className="footer__link" key={link.id}>
                {link.name}
              </a>
            ))}
        </div>
        <div className="footer__copyright">LoudCurtain &copy; 2021</div>
        <div className="footer__secrettagline">
          ⠺⠑ ⠅⠝⠕⠺ ⠊⠞ ⠊⠎ ⠍⠑⠁⠝⠞ ⠞⠕ ⠃⠑ ⠋⠑⠇⠞⠲
        </div>
      </div>
    </footer>
  );
}
